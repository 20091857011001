import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fe4ebea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news-wrap p-b-80" }
const _hoisted_2 = { class: "news-content w-1200 p-t-80 p-b-20" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "first-name f-s-28 col-b-title m-t-16 pointer" }
const _hoisted_5 = { class: "f-s-14 col-b-title m-t-10 opacity-3" }
const _hoisted_6 = { class: "new-flow f-s-14 col-b-title m-t-10 opacity-7" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "new-left-info" }
const _hoisted_9 = { class: "f-s-28 col-b-title m-t-16" }
const _hoisted_10 = { class: "f-s-14 col-b-title m-t-10 opacity-3" }
const _hoisted_11 = { class: "new-flow f-s-14 col-b-title m-t-10 opacity-7" }
const _hoisted_12 = { class: "new-right-img" }
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "first-new",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onToDetail(_ctx.newsList[0].id)))
      }, [
        (_ctx.newsList[0])
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.newsList[0] && _ctx.newsList[0].cover,
              class: "new-big-img w-1200 pointer",
              alt: ""
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.newsList[0] && _ctx.newsList[0].title), 1),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.newsList[0] && _ctx.newsList[0].created_at), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.newsList[0] && _ctx.newsList[0].desc), 1)
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsList.slice(1), (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "new-info-wrap flex-row flex-no-wrap justify-between align-start m-t-60 pointer",
          onClick: ($event: any) => (_ctx.onToDetail(item.id)),
          key: item.id
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(item.title), 1),
            _createElementVNode("div", _hoisted_10, _toDisplayString(item.created_at), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(item.desc), 1)
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("img", {
              src: item.cover,
              alt: ""
            }, null, 8, _hoisted_13)
          ])
        ], 8, _hoisted_7))
      }), 128)),
      (_ctx.showMore)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "f-s-14 col-b-blue m-t-30 pointer text-center",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onGetData && _ctx.onGetData(...args)))
          }, "加载更多"))
        : _createCommentVNode("", true)
    ])
  ]))
}