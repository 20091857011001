
import {getNews} from "@/api/webIndex";
import {defineComponent, onMounted, ref} from "vue";
import {useRouter} from "vue-router";

export default defineComponent({
  setup() {
    interface newItem {
      id: number,
      title: string,
      desc: string,
      cover: string,
      created_at: string
    }

    const
        router = useRouter()

    const newsList = ref<newItem[] | any>([]),
        page = ref<number>(0),
        showMore = ref<boolean>(true)

    const
        onGetData = async () => {
          page.value += 1
          try {
            let res: any = await getNews({page: page.value, per_page: 10})
            if (res.message === 'ok') {
              res.data.last_page <= page.value && (showMore.value = false)
              newsList.value = [...newsList.value, ...res.data?.data]
            }
          } catch (e) {
            console.log(e)
          }
        },
        onToDetail = (id: number) => {
          console.log(id)
          router.push({
            name: 'newDetail',
            query: {
              id
            }
          })
        }

    onMounted(() => {
      onGetData()
    })

    return {
      newsList,
      showMore,
      onGetData,
      onToDetail
    }
  }
})
